.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.login__google {
  background-color: #4285f4;
}

.login div {
  margin-top: 7px;
}

.login__btn:hover, .login__google:hover {
  cursor: pointer;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 768px) {
  .MuiTabs-flexContainer {
    justify-content: center;
  }
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #009b86;
}

.MuiMenu-paper {
  background-color: #009b86 !important;
}

.MuiTabPanel-root {
  padding-top: 0px !important;
}

.MuiPaper-root-MuiAppBar-root {
  box-shadow: rgba(54, 54, 54, 0.16) 0px 0px 1px 0px,
rgba(54, 54, 54, 0.02) 0px 4px 4px 0px,
rgba(54, 54, 54, 0.04) 0px 2px 8px 0px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  width: 300px;
  margin: 10px;
}

div {
  display: block;
  margin: 0 auto;
  position: relative;
}

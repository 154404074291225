.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  text-align: left;
}

label {
  margin-bottom: 5px;
}

.form-container {
  width: 200px;
  margin: 0 auto; /* This centers the element horizontally */
}